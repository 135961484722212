import dynamic from 'next/dynamic';
import { Fragment } from 'react';
import { NextSeo } from 'next-seo';
import Header from '../components/Header';
import Footer from '../components/Footer';
import HomeWidgetMobile from '../components/home/HomeWidgetMobile';
import HomeCarousel from '../components/home/HomeCarousel';
import HomeNews from '../components/home/HomeNews';
import HomeUsefool from '../components/home/HomeUsefool';
import HomeWidget from '../components/home/HomeWidget';

import location from '../data/location.json';

import { useLocationProvider } from '../context/location';

const HomeSEO = dynamic(() => import('../components/home/HomeSEO'));

const HomePage = () => {
  const [locationState] = useLocationProvider();

  return (
    <Fragment>
      {<NextSeo
        title="База - каталог и новости недвижимости в России"
        description="Продажа аренда недвижимости в России и областях. Объявления, каталог, листинг, мультилистинг, карта, поиск, цены, купить, продать, арендовать, сдать, снять недвижимость квартиры студии однушки двушки трешки евродвушки евротрешки дома участки таунхаусы аппартаменты офис склад склады офисы коммерция коммерчесская недвижимость. Новости недвижимости в России: последние новости о недвижимости, ипотеке, спрос, аналитика, изменения в налогах, ключевая ставка, новости росреестра, новости ипотека, блог о недвижимости"
        openGraph={{
          url: `https://${process.env.NEXT_PUBLIC_SITEDOMAIN}`,
          type: 'website',
          title: "База - каталог и новости недвижимости в России",
          description: "Продажа аренда недвижимости в России и областях. Объявления, каталог, листинг, мультилистинг, карта, поиск, цены, купить, продать, арендовать, сдать, снять недвижимость квартиры студии однушки двушки трешки евродвушки евротрешки дома участки таунхаусы аппартаменты офис склад склады офисы коммерция коммерчесская недвижимость. Новости недвижимости в России: последние новости о недвижимости, ипотеке, спрос, аналитика, изменения в налогах, ключевая ставка, новости росреестра, новости ипотека, блог о недвижимости",
          images: [
            {
              url: '/images/og-preview.png'
            }
          ],
          site_name: process.env.NEXT_PUBLIC_SITENAME
        }}
      />}
      <Header />
      <main className="core">
        <HomeWidget region={locationState.code} />
        <HomeWidgetMobile region={locationState.code} />
        <HomeCarousel title="Рекомендуемые на вторичке" category="квартира" link="flats"/>
        <HomeNews />
        <HomeCarousel title="Популярные в коммерции" category="коммерция" link="commercial"/>
        <HomeUsefool region={locationState.code} locations={location} />
        <HomeSEO />
      </main>
      <Footer seo="Купить продать снять сдать недвижимость в России" />
    </Fragment>
  );
};

export default HomePage;
